/* #region mission interface */
export interface IMissionListResponse {
  meta?: {
    nextCursor?: string;
    prevCursor?: string;
    [key: string]: any;
  };
  records: IMissionRecord[];
  [key: string]: any;
}
export interface IMissionRecord {
  _id: string;
  siteId: string;
  missionId: string;
  assetIds: string[];
  projectId: string;
  operatorIds?: any[];
  name?: string;
  objective?: any;
  status?: string;
  pilotIds?: any[];
  drones?: any[];
  flightIds?: any[];
  location?: string;
  area?: {
    mode?: string;
    radius?: number;
    coordinate?: IMissionCoordinate[];
    kmlUrl?: string;
    [key: string]: any;
  };
  missionSchedules?: any[];
  attachmentIds?: any[];
  deletedAt?: any;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  [key: string]: any;
}
export interface IMissionCoordinate {
  latitude?: string;
  longitude?: string;
  [key: string]: any;
}
export interface ISingleMissionResponse {
  _id: string;
  siteId: string;
  assetIds: string[];
  projectId: string;
  objective: IMissionObjective
  createdAt: string;
  updatedAt: string;
  [key: string]: any;
}
export interface IMissionObjective {
  _id: string;
  name: string;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  [key: string]: any;
}
/* #endregion mission interface */

/* #region data resource interface */
export interface IDataResourceListResponse {
  records?: (IDataResourceRecord)[] | null;
  meta?: {
    nextCursor?: string;
    prevCursor?: any;
    [key: string]: any;
  };
  [key: string]: any;
}
export interface IDataResourceRecord {
  _id: string;
  projectId: string;
  missionId: string;
  siteId: string;
  resourceType: string;
  tags?: (string)[] | null;
  parentResourceId?: string | null;
  metadata: {
    exif: IDataResourceExif;
    [key: string]: any;
  };
  storage: {
    storageType: string;
    files: (IDataResourceFilesEntity)[];
    [key: string]: any;
  };
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  workspaceId: string;
  recordType: string;
  id?: string;
  [key: string]: any;
}
export interface IDataResourceExif {
  dateTimeOriginal: string;
  cameraModel: string;
  fieldOfViewDegree: number;
  focalLengthMM: number;
  focalLength35Efl: number;
  imageWidth: number;
  imageHeight: number;
  gpsLatitude: number;
  gpsLongitude: number;
  relativeAltitudeMeters: number;
  absoluteAltitudeMeters: number;
  flightPitchDegree: number;
  flightYawDegree: number;
  flightRollDegree?: null;
  gimbalPitchDegree: number;
  gimbalYawDegree: number;
  gimbalRollDegree: number;
  sensorWidthMM: number;
  sensorHeightMM: number;
  gpsMapDatum: string;
  gpsLatitudeRef: string;
  gpsLongitudeRef: string;
  [key: string]: any;
}
export interface IDataResourceFilesEntity {
  s3Key: string;
  s3Bucket: string;
  sizeBytes: number;
  extension: string;
  s3Etag: string;
  origUploadRelativePath: string;
  preSignedUrl: string;
  [key: string]: any;
}
/* #region data resource interface */

/* #region scene definition document interface */
export interface ISceneDefinitionDocumentVersionListResponse {
  records: ISceneDefinitionDocumentVersionRecord[];
  meta?: {
    nextCursor: string;
    prevCursor: any;
    [key: string]: any;
  };
  [key: string]: any;
}
export interface ISceneDefinitionDocumentVersionRecord {
  _id: string;
  name: string;
  projectId: string;
  missionId: string;
  siteId: string;
  tags?: string[];
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  recordType: string;
  id?: string;
  [key: string]: any;
}
export interface ISceneDefinitionDocumentSingleVersionResponse {
  _id: string;
  name: string;
  projectId: string;
  missionId: string;
  siteId: string;
  globalCrs?: any;
  propertyDefinitions: IScenePropertyDefinition[];
  sceneObjects: ISceneObject[];
  sceneDetails: {
    gravityVector: any;
    groundPlane: any;
    [key: string]: any;
  };
  tags?: string[];
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  recordType: string;
  id: string;
  [key: string]: any;
}
export interface IScenePropertyDefinition {
  allowedValues?: string[];
  name: string;
  type: string;
  displayName: string;
  description: string;
  assignableObjects?: string[];
  minAllowedValue?: number;
  maxAllowedValue?: number;
  [key: string]: any;
}
export interface ISceneObject {
  _id: string;
  name?: string;
  id: string;
  type: string;
  translation: number[];
  scale: number[];
  rotation: number[][];
  coordinates: number[] | number[][];
  properties: {
    dims_order: string;
    telco_equipment_type?: string;
    telco_tower_landmark?: string;
    component_reference?: string;
    confidence?: number;
    telco_equipment_reference_point?: number[];
    telco_equipment_reference_vector?: number[];
    [key: string]: any;
  };
  sceneDocId: string;
  __v?: number;
  [key: string]: any;
  isOpenVisualGallery?: boolean;
}

export interface IMeasurementSceneObject {
  _id: string;
  name?: string;
  id: string;
  type: string;
  translation: number[];
  scale: number[];
  rotation: number[][];
  coordinates: number[][];
  properties: {
    dims_order: string;
    telco_equipment_type?: string;
    telco_tower_landmark?: string;
    component_reference?: string;
    confidence?: number;
    telco_equipment_reference_point?: number[];
    telco_equipment_reference_vector?: number[];
  };
  sceneDocId: string;
  __v?: number;
}
export interface IAIProposedObject {
  name?: string;
  id: string;
  translation: number[];
  scale: number[];
  rotation: number[][];
  __v?: number;
  [key: string]: any;
}
/* #endregion scene definition document interface */

/* #region components interface */
export interface IComponentListResponse {
  records: IComponentRecord[];
  meta: {
    nextCursor?: string;
    prevCursor?: any;
    [key: string]: any;
  }
  [key: string]: any;
}
export interface IComponentRecord {
  _id: string;
  assetId: string;
  workspaceId: string;
  componentName: string;
  componentStatus: string;
  componentType: string;
  xPosition: string;
  yPosition: string;
  zPosition: string;
  installationDate: string;
  externalClientSiteId: string;
  externalClientAssetId: string;
  externalClientComponentId: string;
  isMigratedData: string;
  metadata?: IComponentMetadata;
  createdAt: string;
  updatedAt: string;
  [key: string]: any;
}
export interface IComponentMetadata {
  siteReferenceId?: string;
  ownerCompany?: string;
  assetReferenceId?: string;
  antennaName?: string;
  antennaType?: string;
  antennaClassCode?: string;
  manufacturer?: string;
  modelNumber?: string;
  item?: string;
  serialNumber?: string;
  status?: string;
  numberOfPorts?: string;
  antennaInstallDate?: string;
  installationDate?: string;
  mountingHeightM?: string;
  lengthMM?: string;
  widthMM?: string;
  depthMM?: string;
  weightKG?: string;
  lowestFrequencyMHZ?: string;
  highestFrequencyMHZ?: string;
  locationOrientationDesignDEG?: string;
  verticalSeparationRequiredMM?: string;
  horizontalSeparationRequiredMM?: string;
  apertureSizeMM?: string;
  verticalMountingDownTilt?: string;
  inverted?: string;
  midPointHeightM?: string;
  mountingPosition?: string;
  XdesignM?: string;
  YdesignM?: string;
  ZdesignM?: string;
  directionOrientationDesignDEG?: string;
  associatedStructure?: string;
  associatedFeederCables?: string;
  associatedTrays?: string;
  legacyAssetID?: string;
  proposedCustomerRemoval?: string;
  proposedRemovalDate?: string;
  antHPlaneBeamWidthDEG?: string;
  antEPlaneBeamWidthDEG?: string;
  [key: string]: any;
}

/* #endregion components interface */

/* #region workflow run interface */
export interface IWorkflowrunListResponse {
  meta?: {
    nextCursor?: string;
    prevCursor?: string;
    [key: string]: any;
  };
  records?: IWorkflowRunRecord[];
  [key: string]: any;
}
export interface IWorkflowRunRecord {
  _id: string;
  name: string;
  workflowDefId: string;
  airflowDagId?: string;
  airflowDagRunId?: string;
  projectId: string;
  siteId: string;
  missionId: string;
  status?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  __v: number;
  recordType: string;
  workflowSteps: IWorkflowStep[];
  id?: string;
  [key: string]: any;
}
export interface IWorkflowStep {
  _id: string;
  stepName: string;
  stepType: string;
  airflowTaskId: string;
  status: string;
  parentStepNames?: string[];
  __v?: number;
  progress?: IWorkflowProgress
  [key: string]: any;
}
export interface IWorkflowProgress {
  percentageCompleted?: number;
  detail?: string;
  attempt?: number;
  _id: string;
  [key: string]: any;
}
/* #endregion workflow run interface */

/* #region nadir view interface */
export interface IFromAboveViewDataresouece {
  projectId: string,
  missionId: string,
  resource_type: string,
  tags: string,
  pageLimit: number,
  pre_signed_url?: boolean
}
export interface IFromAboveViewDataResourceListResponse {
  records: IFromAboveRecord[]
  meta: IFromAbovedataMeta
}
export interface IFromAboveRecord {
  _id: string
  projectId: string
  missionId: string
  siteId: string
  resourceType: string
  tags: string[]
  parentResourceId: any
  metadata: IFromAboveMetadata
  storage: IFromAboveStorage
  createdAt: string
  updatedAt: string
  __v: number
  recordType: string
  id: string
}
export interface IFromAboveMetadata {
  exif?: IFromAboveExif
  ImageDescription?: string
  Make?: string
  Model?: string
  Orientation?: number
  XResolution?: number
  YResolution?: number
  ResolutionUnit?: number
  Software?: string
  DateTime?: string
  YCbCrPositioning?: number
  ExifIFDPointer?: number
  GPSInfoIFDPointer?: number
  undefined?: number[]
  ExposureTime?: number
  FNumber?: number
  ExposureProgram?: string
  ISOSpeedRatings?: number
  ExifVersion?: string
  DateTimeOriginal?: string
  DateTimeDigitized?: string
  ComponentsConfiguration?: string
  ShutterSpeedValue?: number
  ApertureValue?: number
  ExposureBias?: number
  MaxApertureValue?: number
  SubjectDistance?: number
  MeteringMode?: string
  LightSource?: string
  Flash?: string
  FocalLength?: number
  MakerNote?: number[]
  FlashpixVersion?: string
  ColorSpace?: number
  PixelXDimension?: number
  PixelYDimension?: number
  InteroperabilityIFDPointer?: number
  FileSource?: string
  SceneType?: string
  CustomRendered?: string
  ExposureMode?: number
  WhiteBalance?: string
  DigitalZoomRation?: number
  FocalLengthIn35mmFilm?: number
  SceneCaptureType?: string
  GainControl?: string
  Contrast?: string
  Saturation?: string
  Sharpness?: string
  DeviceSettingDescription?: number[]
  GPSVersionID?: string
  GPSLatitudeRef?: string
  GPSLatitude?: number[]
  GPSLongitudeRef?: string
  GPSLongitude?: number[]
  GPSAltitudeRef?: number
  GPSAltitude?: number
  GPSStatus?: string
  GPSMapDatum?: string
  thumbnail?: IFromAboveThumbnail
}
export interface IFromAboveExif {
  dateTimeOriginal: string
  cameraMode: string
  fieldofviewDegree: string
  flightAltitudeM: number
  focalLengthMM: number
  focallLength35Ef1: number
  imageWidth: number
  imageHeight: number
  gpsLatitude: number
  gpsLongitude: number
  relativeAltitudeMeters: number
  absoluteAltitudeMeters: number
  flightPitchDegree: number
  flightvawDegree: number
  flightRollDegree: number
  gimbalPitchDegree: number
  gimbalYawDegree: number
  gimbalRollDegree: number
  sensorWidthMM: number
  sensorHeightMM: number
}

export interface IFromAboveThumbnail {
  Compression: number
  XResolution: number
  YResolution: number
  ResolutionUnit: number
  JpegIFOffset: number
  JpegIFByteCount: number
}
export interface IFromAboveStorage {
  storageType: string
  files: IFromAboveFile[]
}
export interface IFromAboveFile {
  s3Key: string
  s3Bucket: string
  sizeBytes: number
  extension: string
  s3Etag: string
  origUploadRelativePath: string,
  preSignedUrl: string
}
export interface IFromAbovedataMeta {
  nextCursor: string
  prevCursor: any
}
export interface IFromAbovecalculateImageExtents {
  topLeftLat: number;
  topLeftLon: number;
  bottomRightLat: number;
  bottomRightLon: number;
}
/* #endregion nadir view interface */

/* #region annotation canvas interface */
export interface IGeometryAnnotationListResponse {
  records: IGeometryAnnotationDto[];
  meta?: {
    nextCursor: string;
    prevCursor: any;
    [key: string]: any;
  };
  [key: string]: any;
}
export interface IGeometryAnnotationDto {
  projectId: string;
  missionId: string;
  dataResourceId: string;
  geometry: {
    title?: string,
    type?: string,
    coordinates: any[]
  },
  confidence: number;
  classLabels: any[];
  collectionId: string;
  tags: any[],
  metadata?: {},
  coorinate?: any[],
  stageProperty?: {
    id: string;
    width: number;
    height: number;
  }
  verify_checked?: boolean,
  verify_removed?: boolean,
  findingId?: string,
  id?: number;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
}
export interface IframeSortMetadata {
  exif: IframeSortExif
}
export interface IframeSortExif {
  dateTimeOriginal?: string
  cameraModel: string
  fieldOfViewDegree: number
  focalLengthMM?: number
  focalLength35Efl: number
  imageWidth: number
  imageHeight: number
  gpsLatitude: number
  gpsLongitude: number
  relativeAltitudeMeters: number
  absoluteAltitudeMeters: number
  absolute_altitude_m: number
  flightPitchDegree: number
  flightYawDegree: number
  flightRollDegree: any
  gimbalPitchDegree: number
  gimbalYawDegree?: number
  gimbalRollDegree: number
  sensorWidthMM: number
  sensorHeightMM?: number
  gimbal_yaw_degree?: number
}

export interface IframeSortStorage {
  storageType: string
  files: File[]
}

export interface IframeSortFile {
  s3Key: string
  s3Bucket: string
  sizeBytes: number
  extension: string
  s3Etag: string
  origUploadRelativePath: string
  preSignedUrl: string
}
export interface ISingleSiteResponse {
  _id: string;
  attachedAsset: any;
  externalClientSiteId: string;
  industryType: string;
  metadata: any
  createdAt: string;
  updatedAt: string;
  recordType: string;
  siteLatitude: string
  siteLongitude: string;
  siteName: string;
  siteType: string;
}
/* #endregion annotation canvas interface */

/* #region site detail interface */
export interface ISiteDetailResponse {
  _id: string;
  siteName: string;
  siteType: string;
  siteLatitude: string;
  siteLongitude: string;
  workspaceId: string;
  externalClientSiteId: string;
  isMigratedData: boolean;
  industryType: string;
  metadata: ISiteMetadata;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  recordType: string;
  attachedAsset?: (ISiteAttachedAssetEntity)[] | null;
  id: string;
  [key: string]: any;
}
export interface ISiteMetadata {
  siteName: string;
  siteReferenceId: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  town: string;
  state: string;
  postcode: string;
  siteType: string;
  region: string;
  infrastructureOwner: string;
  siteNominalCreationDate: string;
  anchorTenant: string;
  rfnsasiteno: string;
  acmasiteid: string;
  nominalLatitude: string;
  nominalLongitude: string;
  nominalAddress: string;
  siteAccessRestriction: string;
  propertyType: string;
  siteRLM: string;
  customerSiteName: string;
  siteAccess: string;
  legalAddressID: string;
  mapDatum: string;
  zone: string;
  easting: string;
  northing: string;
  coordPrecision: string;
  telstraSiteNo: string;
  telstraAddressID: string;
  rfnsaRating: string;
  rfnsaRatingReason: string;
  latitude: string;
  longitude: string;
  siteAccessCondition: string;
  [key: string]: any;
}
export interface ISiteAttachedAssetEntity {
  _id: string;
  siteId: string;
  workspaceId: string;
  assetName: string;
  assetType: string;
  assetHierarchy: IAssetHierarchy;
  assetLatitude: number;
  assetLongitude: number;
  assetOrientation: number;
  assetHeight: number;
  assetStatus: string;
  externalClientSiteId?: string;
  externalClientAssetId: string;
  isMigratedData: boolean;
  metadata: IAttachedAssetMetaData;
  createdAt: string;
  updatedAt: string;
  recordType: string;
  id: string;
  [key: string]: any;
}
export interface IAttachedAssetMetaData {
  siteReferenceId: string;
  ownerCompany: string;
  structureReferenceId: string;
  structureName: string;
  manufacturer: string;
  modelNumber: string;
  item: string;
  serialNumber: string;
  status: string;
  installationDate: string;
  siteCategory: string;
  structureClassCode: string;
  structureType: string;
  heightM: string;
  structureInstallationDate: string;
  heightExtensionM: string;
  orientationDesignDEG: string;
  orientationActualDEG: string;
  warningLights: string;
  structureRLM: string;
  siteHeightRLM: string;
  accessRestriction: string;
  structureLoadPercentage: string;
  structureLoadVariance: string;
  foundationLoadPercentage: string;
  foundationLoadVariance: string;
  structureClassification: string;
  anchorTenant: string;
  structureStrengtheningApplied: string;
  structureStrengtheningType: string;
  foundationType: string;
  foundationStrengtheningApplied: string;
  foundationStrengtheningType: string;
  certificationLink: string;
  guysLoadPercentage: string;
  guysLoadVariance: string;
  accessType: string;
  topPosition: string;
  latitude: string;
  longitude: string;
  criticalWindDirection: string;
  structureDrawings: string;
  legacyAssetNumber: string;
  legacyAssetOwner: string;
  [key: string]: any;
}
/* #endregion site detail interface */

export interface ILabel {
  _id: string;
  name: string;
  workspaceId: string;
  labelType: string;
  metadata: Record<string, any>;
  deletedAt: string | null;
  deletedBy: string | null;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  description?: string; // Optional field
}

export interface ILabelResponse {
  meta?: {
    nextCursor?: string;
    prevCursor?: string;
  };
  records: ILabel[];
}
export interface ISceneObjectProperties{
  dims_order: string;
  telco_equipment_type: string;
  telco_tower_landmark: string;
  component_reference: string;
  confidence: number;
  telco_equipment_reference_point: number[];
  telco_equipment_reference_vector: number[];
  annotation_2d_references: string[]
}

export interface IComponentStatusSettings { 
  [key: string] : {
    color: string;
    isRender: boolean;
  };
}

export interface IAssetHierarchy {
  [key: string]: string
}